<template>
  <div id="app">
    <div
      class="sso-login-wrap"
      :style="{ background: 'url(' + backgroundImg + ') no-repeat' }"
    >
      <div class="sso-login-container">
        <!-- 左侧描述信息 -->
        <div class="description">
          <!-- logo 展示 -->
          <div class="description-logo">
            <img v-if="leftLogoImg" :src="leftLogoImg" alt="" />
            <img v-if="centerLogoImg" :src="centerLogoImg" alt="" />
            <img v-if="rightLogoImg" :src="rightLogoImg" alt="" />
          </div>
          <!-- 副标题 -->
          <p
            v-html="subtitle"
            class="description-subtitle"
            :style="{
              fontSize: subtitleFontSize + 'px',
              fontFamily: subtitleFont,
            }"
          ></p>
          <!-- 主标题 -->
          <p
            v-html="mainTitle"
            class="description-main-title"
            :style="{
              fontSize: mainTitleFontSize + 'px',
              fontFamily: mainTitleFont,
            }"
          ></p>
          <!-- 副标题 -->
          <p
            v-html="title"
            class="description-subtitle"
            :style="{ fontSize: titleFontSize + 'px', fontFamily: titleFont }"
          ></p>
        </div>
        <!-- 右侧登录框 -->
        <div class="login-box">
          <img
            v-if="loginLogo !== ''"
            class="description-main-logo"
            :src="loginLogo"
            alt=""
          />
          <p v-if="loginLogo === ''">{{ $t('dms.common_pleaseSignIn') }}</p>
          <!-- 输入框 -->
          <div class="input-group">
            <div class="username">
              <div class="title">{{ $t('dms.common_User') }}</div>
              <yl-input
                v-model="userName"
                width="290"
                height="40"
                :class="{
                  'input-pwd-err':
                    isErr &&
                    errorMsg
                      .toLowerCase()
                      .indexOf($t('dms.common_User').toLowerCase()) > -1,
                }"
                @input="fn_Input"
                @keyup.enter="fn_Login(false)"
                class="input_userName"
              ></yl-input>
            </div>
            <div class="password">
              <div class="title">{{ $t('dms.common_Password') }}</div>
              <yl-input
                v-model="passWord"
                password
                width="290"
                height="40"
                :class="{
                  'input-pwd-err':
                    isErr &&
                    errorMsg
                      .toLowerCase()
                      .indexOf($t('dms.common_Password').toLowerCase()) > -1,
                }"
                @input="fn_Input"
                @keyup.enter="fn_Login(false)"
              ></yl-input>
            </div>
            <!-- 错误信息 -->
            <div :class="!isErr ? 'errorMsg1' : 'errorMsg'">{{ errorMsg }}</div>
          </div>
          <yl-button
            type="primary"
            class="btn-submit"
            @click="fn_Login(false)"
            >{{ $t('dms.common_Login') }}</yl-button
          >
          <!-- 用户协议 -->
          <yl-checkbox v-model="checked" v-if="ProtocolName"
            >
            <span @click.prevent style="cursor: auto;">{{ $t('dms.common_pleaseAgree') }}</span>
            <span @click.prevent="fn_AgreeProtocol" style="color: blue;">
              <span v-if="locale === 'zh_CN' || locale === 'zh_TW'"
                >《{{ ProtocolName }}》</span
              >
              <!-- v-if="locale === 'en_US'" -->
              <span v-else
                > {{ ProtocolName }}</span
              >
            </span></yl-checkbox
          >
          <br />
          <!-- 多语言 -->
          <!-- <yl-select
            v-if="isOpenMultiLang"
            width="180"
            :options="localList"
            v-model="locale"
            option-value="id"
            option-text="name"
            :placeholder="$t('dms.common_switchLanguage')"
            requireWidth="100"
            @change="fn_changeLocal"
          ></yl-select> -->
          <img
            class="yawlih-logo"
            src="../../../public/resource/images/yawlih-logo.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 版权信息 -->
    <div class="footer-info">
      {{ footerInfo }}
    </div>
    <yl-modal
      class="sso-login-modal"
      v-model="isShow"
      :showClose="true"
      :title="$t('dms.common_hello')"
    >
      <p>
        {{ $t('dms.common_agreeModalTitle') }}
      </p>
      <p>
        {{ $t('dms.common_agreeModalContent') }}
      </p>
      <div slot="footer">
        <yl-button @click="isShow = false">{{
          $t('dms.common_agreeModalCancel')
        }}</yl-button>
        <yl-button type="primary" @click="fn_ContinueLogin">{{
          $t('dms.common_agreeModalEnter')
        }}</yl-button>
      </div>
    </yl-modal>
    <yl-modal
      :class="{
        'sso-protocol-modal': true,
        'sso-protocol-word': pdfUrl === '',
      }"
      v-model="isShowProtocol"
      :showClose="true"
      :ok-text="$t('dms.common_Confirm')"
      cancelText=" "
      title=" "
    >
      <embed
        v-if="pdfUrl"
        :src="pdfUrl"
        type="application/pdf"
        width="100%"
        height="100%"
      />
      <!-- <div v-else id="bodyContainer" v-html="docxContent">
      </div> -->
      <!-- <embed :src="pdfUrl" type="application/pdf" width="100%" height="100%" /> -->
    </yl-modal>
    <selectComp
      style="display: none;"
      v-if="loginData.length === 1"
    ></selectComp>
  </div>
</template>

<script>
import * as login from '../../api/login'
import * as common from '../../api/common'
import loginInfo from '../../assets/js/loginSSO'
import selectComp from './selectSystem.vue'

export default {
  name: 'loginSSO',
  components: { selectComp },
  data() {
    return {
      userName: '',
      passWord: '',
      errorMsg: '',
      isErr: false,
      checked: Boolean(localStorage.getItem('checkProtocol')),
      subtitle: '',
      mainTitle: '',
      title: '',
      footerInfo: '',
      backgroundImg:
        "url('../../../public/resource/images/login-background.jpg')",
      locale: localStorage.getItem('lang') || 'zh_CN',
      localList: [
        { id: 'zh_CN', name: '简体中文' },
        { id: 'en_US', name: 'English' },
        { id: 'zh_TW', name: '繁體中文' },
      ],
      picList: [],
      loginLogo: '',
      subtitleFontSize: 25,
      mainTitleFontSize: 50,
      titleFontSize: 25,
      subtitleFont: '',
      mainTitleFont: '',
      titleFont: '',
      isShow: false,
      isShowProtocol: false,
      pdfUrl: '',
      loginData: [],
      ProtocolName: '',
      leftLogoImg: '',
      centerLogoImg: '',
      rightLogoImg: '',
      docxContent: '',
      isOpenMultiLang: false,
      CheckLanguages:[]
    }
  },
  methods: {
    fn_Login(ForceVal) {
      let name = this.locale === 'zh_CN' || this.locale === 'zh_TW' ? `《${this.ProtocolName}》` : ' ' + this.ProtocolName
      if (!this.fn_Validate()) {
        return
      }

      if (!this.checked&&this.ProtocolName) {
        this.$toast({
          message: this.$t('dms.common_pleaseAgree') + name,
          duration: 2500,
          type: 'error',
        })
        return
      }
      var param = {
        userName: this.userName,
        password: hex_md5(this.passWord),
        Force: ForceVal,
      }
      login
        .fn_Login(param, true)
        .then((res) => {
          if (res.data.error) {
            if (res.data.error === '该账号已经登录，请确认是否登录') {
              this.isShow = true
            } else {
              this.errorMsg = res.data.error
              this.isErr = true
            }
          } else {
            this.fn_Store(res)
          }
        })
        .catch((err) => {})
    },
    fn_Store(res) {
      const { set: storeSet, remove: storeRemove } = loginInfo.SSOStore
      const responseData = res && res.data && res.data.data ? res.data.data : {}
      this.fn_SpecialLogic(responseData)

      for (const key in storeSet) {
        let info = responseData[storeSet[key]]

        if (typeof storeSet[key] === 'string') {
          localStorage.setItem(key, info||'')
        } else if (storeSet[key].length === 1) {
          localStorage.setItem(
            key,
            JSON.stringify(responseData[storeSet[key][0]])
          )
        }
      }
      for (const removeKey in storeRemove) {
        localStorage.removeItem(removeKey, responseData[storeRemove[removeKey]])
      }
      this.fn_GetLanguageList()
      
    },
    fn_GetLanguageList(ForceVal) {
      var that = this;
      var param = {
        TopicID:localStorage.getItem('TopicID')||''
      }
      common
        .fn_GetLanguageList(param, true)
        .then((res) => {
          if (res.data.error) {
            that.$toast({
              message: res.data.error,
              type: 'error'
            })
          } else {
            that.fn_GetLoginData()
            if (that.loginData.length !== 1) {
              that.$router.push({ path: '/selectSystem' })
            } 
            that.CheckLanguages = res.data.data.Languages.filter(x=>x.Status==1);
            if(localStorage.getItem('TopicID')){
              localStorage.setItem('Languages',JSON.stringify(that.CheckLanguages));
            }else{
              localStorage.setItem('Languages',JSON.stringify(res.data.data.Languages));
            }
           
          }
        })
        .catch((err) => {})
    },


    // 处理特殊的业务存储逻辑
    fn_SpecialLogic(responseData) {
      this.fn_DataMGR(responseData)
    },
    // 病患的特殊存储逻辑
    fn_DataMGR(responseData) {
      let roleCode = responseData.roleCode
      if (!roleCode) return

      let roleMappings = [
        { code: ['超级管理员'], role: '超级管理员' },
        { code: ['医院管理员'], role: '医院管理员' },
        { code: ['课题管理员'], role: '课题管理员' },
        { code: ['科室管理员', '病区管理员'], role: '科室管理员' },
        { code: ['随访医生', '随访护士'], role: '随访医生' },
        { code: ['运营管理员'], role: '运营管理员' },
      ]

      let role = ''

      for (let mapping of roleMappings) {
        if (mapping.code.some((code) => roleCode.includes(code))) {
          role = mapping.role
          break
        }
      }
      localStorage.setItem('role', role)
    },
    fn_Input() {
      this.errorMsg = ''
      this.isErr = false
    },
    fn_Validate() {
      this.errorMsg = ''
      this.isErr = false
      if (this.userName.trim() == '') {
        this.errorMsg = this.$t('dms.common_LoginTip1')

        this.isErr = true
        return false
      } else if (this.passWord.trim() == '') {
        this.errorMsg = this.$t('dms.common_LoginTip2')
        this.isErr = true
        return false
      }
      return true
    },
    fn_changeLocal() {
      this.$i18n.locale = this.locale
      localStorage.setItem('lang', this.locale || 'zh_CN')
      this.fn_Input()
    },
    fn_ContinueLogin() {
      this.isShow = false
      this.fn_Login(true)
    },
    fn_AgreeProtocol() {
      this.isShowProtocol = true
    },
    fn_InitImg(urlName) {
      let params = {
        type: 'image',
        name: urlName,
        source: 'upload',
      }
      return common.fn_View2(params)
    },
    fn_GetConfigs() {
      login
        .fn_GetConfigs({}, true)
        .then((res) => {
          if (res.data.error) {
            this.errorMsg = res.data.error
            this.isErr = true
          } else {
            const functionConfig = res.data.data.functionConfig
            functionConfig.forEach((item) => {
              switch (item.code) {
                case 'Common.loginset.copyright':
                  this.footerInfo = item.value
                  localStorage.setItem('copyRight', item.value)
                  break
                case 'Common.loginset.LogoLeft':
                  if(item.value === '') return
                  this.leftLogoImg = this.fn_InitImg(
                    (item.value).split('###')[0]
                  )
                  break
                case 'Common.loginset.LogoRight':
                  if(item.value === '') return
                  this.rightLogoImg = this.fn_InitImg(
                    (item.value || '').split('###')[0]
                  )
                  break
                case 'Common.loginset.LogoCenter':
                  if(item.value === '') return
                  this.centerLogoImg = this.fn_InitImg(
                    (item.value || '').split('###')[0]
                  )
                  break
                case 'Common.loginset.loginLogo':
                  if(item.value === '') return
                  this.loginLogo = this.fn_InitImg(item.value)
                  break
                case 'Common.loginset.upSubTitle':
                  this.subtitle = item.value
                  break
                case 'Common.loginset.MainTitle':
                  this.mainTitle = item.value
                  break
                case 'Common.loginset.hospital':
                  this.title = item.value
                  break
                case 'Common.loginset.Background':
                  if(item.value === '') return
                  this.backgroundImg = this.fn_InitImg(item.value)
                  break
                case 'Common.loginset.defultLang':
                  this.locale = item.value;
                  localStorage.setItem('lang', this.locale || 'zh_CN')
                  break
                case 'Common.loginset.SwitchLang':
                  localStorage.setItem('isOpenMultiLang', item.value)
                  this.isOpenMultiLang = Boolean(localStorage.getItem("isOpenMultiLang"));
                  break
                case 'Common.loginset.autosave':
                  localStorage.setItem('autosave', item.value)
                  break
                case 'Common.loginset.timeallocation':
                  localStorage.setItem('timeallocation', item.value)
                  break
                case 'Common.loginset.userAgreement':
                  let val = JSON.parse(item.value)
                  if(val){
                    let response = val[val.length - 1].response.data
                    this.ProtocolName = val[val.length - 1].name.split('.')[0]
                    if (response.fileExtension === '.docx') {
                      this.previewDocx('docx', response.fileCode)
                    } else {
                      this.previewDocx('pdf', response.fileCode)
                    }
                  }
                  break
                case 'hospitalType':
                case 'pdfExport':
                case 'presetTopic':
                case 'FollowBtnFlag':
                case 'CustomFormStatus':
                case 'SerialNumber': 
                case 'CaseCollection':             
                  localStorage.setItem(item.code, item.value)
                  break  
                default:
                  break
              }
            })
            if(!localStorage.getItem('lang')){
               localStorage.setItem('lang', 'zh_CN')
            }
           
          }
        })
        .catch((err) => {})
    },
    previewDocx(type, name) {
      let params = {
        type,
        name,
        source: 'upload',
      }
      common
        .fn_View(params)
        .then((res) => {
          if (res.data.error) {
          } else {
            if (type === 'docx') {
              // mammoth
              // .convertToHtml({ arrayBuffer: res.data })
              // .then((result) => {
              //   this.docxContent = result.value;
              // })
              // .catch((err) => {
              //   console.error(err);
              // });
            } else {
              let blob = new Blob([res.data], {
                type: 'application/pdf',
              })
              this.pdfUrl = URL.createObjectURL(blob)
            }
          }
        })
        .catch((err) => {})
    },
    fn_GetLoginData() {
      let powerCodes = JSON.parse(localStorage.getItem('powerCodes'))
      let powerList = loginInfo.powerList

      for (const key in powerList) {
        if (this.fn_checkPowerCodes(powerCodes, key)) {
          this.loginData.push(powerList[key])
        }
      }
    },
    fn_checkPowerCodes(powerCodes, key) {
      const powerCodesList = powerCodes
      const keyList = key.split(',')

      for (let code of powerCodesList) {
        if (keyList.includes(code)) {
          return true
        }
      }

      return false
    },
  },
  watch: {
    userName: function() {
      this.userName = this.userName.replace(/\s+/g, '')
    },
    passWord: function() {
      this.passWord = this.passWord.replace(/\s+/g, '')
    },
    checked(val) {
      if (val) {
        localStorage.setItem('checkProtocol', true)
      }
    }
  },
  mounted() {
    this.fn_GetConfigs()
  },
}
</script>

<style lang="less" scoped>
.sso-login-wrap {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: calc(100% - 26px);
  background: url('../../../public/resource/images/login-background.jpg')
    no-repeat scroll 50% 0 transparent;
  background-size: cover !important;

  .sso-login-container {
    width: 75%;
    margin: 0 10% 0 15%;
    min-height: 660px;
    display: flex;

    .description {
      padding-right: 60px;
      color: white;
      text-align: center;
      flex: 1;
      .description-logo {
        margin-top: 10px;
        text-align: center;
        img {
          width: 242px;
          height: 50px;
          display: inline-block;
          background: #cccccc;
          margin-right: 20px;
        }
      }
      .description-subtitle {
        margin-top: 70px;
      }
      .description-main-title {
        font-weight: bold;
        margin-top: 64px;
      }
    }

    .login-box {
      width: 450px;
      height: 550px;
      text-align: center;
      position: relative;
      background-color: white;
      .description-main-logo {
        width: 242px;
        height: 50px;
        display: inline-block;
        margin: 30px 0 40px 0;
      }
      p {
        font-size: 28px;
        color: #3a5671;
        font-weight: bold;
        margin: 30px 0 40px 0;
      }
      .input-group {
        width: 290px;
        margin: auto;
        .title {
          color: #3a5671;
          text-align: left;
          font-size: 16px;
        }
        /deep/.yl-input-suffix i {
          line-height: 40px;
        }
      }
      /deep/.errorMsg {
        font-size: 14px;
        color: #ff0000;
        min-height: 30px;
        width: 290px;
        line-height: 31px;
        margin-left: 0;
        text-align: left;
      }
      .yl-checkbox-wrapper {
        font-size: 14px;
        color: #3a5671;
        margin-top: 10px;
        margin-right: 0;
        width: 290px;
        text-align: left;
      }
      .btn-submit {
        width: 290px;
        height: 40px;
        margin: 36px 0 0 0;
        background: #2a8cb5;
        border: none;
      }
      .yawlih-logo {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
    }
  }
}
.footer-info {
  position: fixed;
  width: 100%;
  bottom: 2px;
  text-align: center;
  font-size: 14px;
  color: #797979;
}
.sso-login-modal {
  background: #2a8cb5;
  /deep/.yl-modal-wrap .yl-modal {
    width: 410px !important;
    p {
      margin-bottom: 16px;
    }
    .yl-modal-footer {
      button:nth-child(1) {
        border: 1px solid #a1a1a1;
        float: left;
      }
      button:nth-child(2) {
        background: #2a8cb5;
      }
    }
  }
}
.sso-protocol-modal {
  /deep/.yl-modal-wrap .yl-modal {
    width: 1000px !important;
    .yl-modal-content .yl-modal-body {
      height: 600px;
      padding: 0 15px;
      line-height: 1;
    }
  }
}
.sso-protocol-word {
  /deep/.yl-modal-wrap .yl-modal {
    .yl-modal-content .yl-modal-body {
      overflow-y: auto;
    }
  }
}
</style>
